<template>
  <div>
    <b-row>
        <b-col lg="6" md="6" sm="6" class="heading-title">
          <h4>Create Vendor</h4>
          <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
           
            <b-breadcrumb-item active>Create Vendor</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
      </b-row>
      <br />
      <b-card class="mb-4 w-100">
    <b-form>
        <h6 class="custom-text mt-4"><span>Vendor Details</span></h6>
        <b-row>
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  >Company Name <span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  :state="validateState('company_name')"
                  v-model="vendorForm.company_name"
                  type="text"
                  placeholder="Enter company name"
                ></b-form-input>
                <b-form-invalid-feedback id="company_name-1-live-feedback">
                  Please enter company name.
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col> 
          
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  >Company State <span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-select
                  v-model="vendorForm.state" :options="state_code"
                  aria-describedby="company_name-1-live-feedback"
                  :state="validateState('state')"
                ></b-form-select> 
                <b-form-invalid-feedback id="company_name-1-live-feedback">
                  Please enter company name.
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
         
        </b-row>
        
        <b-row >
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="email"
                  >Company Email <span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="email"
                  :state="validateState('email')"
                  v-model="vendorForm.email"
                  type="email"
                  aria-describedby="email-1-live-feedback"
                  placeholder="Enter Company email"
                >
                </b-form-input>
                <b-form-invalid-feedback id="email-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.email.required">
                    Please enter email.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.email.required &&
                      !this.$v.vendorForm.email.email
                    "
                    >Please enter valid email.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="conact"
                  >Company Phone number <span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="contact_no"
                  :state="validateState('contact')"
                  v-model="vendorForm.contact"
                  type="text"
                  placeholder="Enter Company phone number"
                  aria-describedby="contact-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="contact-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.contact.required">
                    Please enter phone number.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.contact.required &&
                      !this.$v.vendorForm.contact.numeric
                    "
                    >The phone number number must be numeric.</span
                  >
                  <span
                    v-if="
                      (this.$v.vendorForm.contact.required &&
                        this.$v.vendorForm.contact.numeric &&
                        !this.$v.vendorForm.contact.minLength) ||
                      !this.$v.vendorForm.contact.maxLength
                    "
                    >The phone number number must be in 10 digit.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>

        </b-row>
        <hr style="border:1px solid #edecec;width:70%" >
        <b-row>
          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >First Name <span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="fname"
                  :state="validateState('first_name')"
                  v-model="vendorForm.first_name"
                  type="text"
                  placeholder="Enter Company Person first name"
                  aria-describedby="first_name-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="first_name-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.first_name.required">
                    Please enter first name.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.first_name.required &&
                      !this.$v.vendorForm.first_name.sameAsPassword
                    "
                    >The first name must be in alphabet.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >Last Name <span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="lname"
                  :state="validateState('last_name')"
                  v-model="vendorForm.last_name"
                  type="text"
                  placeholder="Enter Company  Person last name"
                  aria-describedby="last_name-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="last_name-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.last_name.required">
                    Please enter last name.</span
                  >
                  <span
                    v-if="
                      this.$v.vendorForm.last_name.required &&
                      !this.$v.vendorForm.last_name.sameAsPassword
                    "
                    >The last name must be in alphabet.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="6">
            <b-row class="py-3 align-items-center">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="fname"
                  >Designation <span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="fname"
                  :state="validateState('designation')"
                  v-model="vendorForm.designation"
                  type="text"
                  placeholder="Enter  Designation"
                  aria-describedby="first_name-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="first_name-1-live-feedback">
                  <span v-if="!this.$v.vendorForm.designation.required">
                    Please enter Designation name.</span
                  >
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>

          <!-- <b-col cols="6">
          <b-row class="py-3 align-items-center">
            <b-col cols="4"><label class="fw-medium mb-0" for="venue_name">Venue Name :</label></b-col>
            <b-col cols="7">
              <b-form-input name="venue_name" type="text" placeholder="Enter   venue name"></b-form-input>
            </b-col>
          </b-row>
        </b-col> -->
          <!-- <b-col cols="12">
            <b-row class="py-3 align-items-center">
              <b-col cols="2"
                ><label class="fw-medium mb-0" for="address"
                  >Address :</label
                ></b-col
              >
              <b-col cols="10">
                <b-form-textarea
                  placeholder="Enter address"
                  v-model="vendorForm.address"
                  rows="2"
                  max-rows="2"
                  style="width: 604px"
                >
                </b-form-textarea>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
       
        <b-row>
        
          <b-col cols="6">
           
          </b-col>
        </b-row>
        <b-row>
       
        </b-row>
        <b-row>
         
        </b-row>

        <h6 class="custom-text mt-4"><span>Contract Details</span></h6>
        <b-row>
          <b-col md="6">
           
            <b-row class="mb-4">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  > Contract date<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7" class="form-custom-control">
                {{ self_date_format(vendorForm.contruct_date) }}
                <!-- <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  v-model="vendorForm.contruct_date"
                  type="text"
                  placeholder="Enter Subscribe Amount"
                  :state="validateState('contruct_date')"
                  readonly
                ></b-form-input>
                <b-form-invalid-feedback id="company_name-1-live-feedback">
                  Please Select Contruct Date.
                </b-form-invalid-feedback> -->
                
              </b-col>
					</b-row>
         <!--  <b-row class="mb-4" v-if="include_trail">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  >Free Trail End date<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7" class="form-custom-control">
                {{ self_date_format(end_date) }}
                 <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  v-model="end_date"
                  type="text"
                  readonly
                ></b-form-input> 
              
                
              </b-col>
            </b-row> -->
            <b-row class="mb-4" >
                <b-col cols="4" class="mb-3"><label class="fw-medium mb-0" for="companyName"
                  > End Contract date<span class="text-danger">*</span> :</label
                ></b-col
              >
            
              <b-col cols="7" class="form-custom-control">
               
                  {{ self_date_format(without_trail) }}
                <!-- <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  v-model="with_trail"
                  type="text"
                  readonly
                ></b-form-input> -->
                <!-- </span>
                <span v-else>
                  {{ self_date_format(without_trail) }}
                  {{ self_date_format(with_trail) }}
                 
                 </span> -->
              </b-col>
            
					</b-row>
          <b-form-checkbox
                    id="checkbox-1"
                    v-model="include_trail"
                    name="checkbox-1"
                    class="free_peiod"
                  >
                     Free Trial Period
                  </b-form-checkbox>
          <b-row  >
                <b-col cols="4" class="mb-3"><label class="fw-medium mb-0" for="companyName"
                  >Billing Start Date<span class="text-danger">*</span> :</label
                ></b-col
              >
                <b-col cols="7" class="form-custom-control" v-if="include_trail">
                {{ self_date_format(end_date) }}</b-col>
                <b-col cols="7" class="form-custom-control" v-else>
                {{ self_date_format(vendorForm.contruct_date) }}</b-col>
          </b-row>
         
        </b-col>
        
          <b-col md="6">
            <b-form-group label="Select Your plan " >
      <b-form-radio-group
        id="radio-group-2"
        v-model="biling_status"
        name="radio-sub-component"
      >
      <b-form-radio value="free">Free</b-form-radio>
        <b-form-radio value="percentage">Commission</b-form-radio>
        <b-form-radio value="subs">Subscription (Yearly) </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <b-row class="ml-3 mr-3 mb-4" v-if="biling_status=='subs'">
              <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  > Amount<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="7">
                <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  v-model="vendorForm.subsamount"
                  type="text"
                  placeholder="Enter Subscribe Amount"
                ></b-form-input>
              
              </b-col>
              <table class="table mt-3">
								
								<tr v-for="(subs,s) in vb_range_subs" :key="s">
                  <td><input type="radio" name="subs"  v-model="subscriptions" @change="subscription_changes" :value="subs.amount" ></td>
									<td> <i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(subs.from)) }}  - <i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(subs.to))}} </td>
									<td><i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(subs.amount)) }}</td>
								</tr>
							</table>
					</b-row>
					<b-row class="ml-3 mr-3 mb-4" v-if="biling_status=='percentage'">
            <b-col cols="4"
                ><label class="fw-medium mb-0" for="companyName"
                  > Percentage<span class="text-danger">*</span> :</label
                ></b-col
              >
              <b-col cols="5">
                <b-form-input
                  name="company_name"
                  aria-describedby="company_name-1-live-feedback"
                  v-model="vendorForm.per_amounts"
                  type="number"
                  placeholder="Enter Percentage "
                ></b-form-input>
              
              </b-col>
              <b-col cols="2">
                <input type="checkbox"
                    id="checkbox-1"
                    v-model="fixed_percentage"
                    name="checkbox-1"
                  >
                     Fixed
              </b-col>
						<b-col cols="12" md="12">
							<table class="table mt-3">
								
								<tr v-for="(ranges,r) in vb_pencentage_list" :key="r">
                  <!-- <td v-if="!fixed_percentage"><input type="radio" name="percentage" v-model="percentages" @change="percentage_changes" :value="ranges.percentage" ></td> -->
									<td> <i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(ranges.range_from)) }}  - <i class="mdi mdi-currency-inr"></i>{{ numberWithCommas(parseInt(ranges.range_to))}} </td>
									<td>{{ ranges.percentage }}%</td>
								</tr>
							</table>
              
             
						</b-col>
					</b-row>
          </b-col>
        </b-row>

        <div class="text-center">
					<b-button to="/Manage_vendors"  class="mt-2 btn-venuecolor">
						Back
					</b-button>
					&nbsp;
					<b-button 
					type="submit"
					class="mt-2 btn-venuecolor"
                    @click="handleOk"
					>
					Add
					</b-button>
				</div>

      </b-form>
      </b-card>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import {
  required,
  //sameAs,
 // alpha,
  email,
  numeric,
  minLength,
  maxLength,
  //helpers
} from "vuelidate/lib/validators";
import helpers from "../../mixins/helpers";
/* const passwordCheck = helpers.regex(
  "passwordCheck",
  /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@&$#%]).*$/
);
const panCardNumberCheck = helpers.regex(
  "panCardNumberCheck",
  /([A-Z]){5}([0-9]){4}([A-Z]){1}$/
);
const gstNumberCheck = helpers.regex(
  "gstNumberCheck",
  /[0-9]{2}([A-Z]){5}([0-9]){4}([A-Z]){1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
); */
import State from '../../assets/statecode.json'

export default {
  name: "managevendors",
  mixins: [validationMixin,helpers],
  data: () => ({
    title: "managevendors",
    myJson: State,
    fixed_percentage: false,
    percentages: '',
    subscriptions: '',
    roles_id: JSON.parse(localStorage.getItem("loggedUserData")).role_id,
    num: 1,
    start_date: '',
    end_date: '',
    without_trail: '',
    with_trail: '',
    state_code: [{ value :'',text:'Select Company State'}],
    vendorForm: {
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      address: "",
      company_name: "",
      city: "",
      state: "",
      pincode: "",
      gst_tin: "",
      pan: "",
      password: "",
      confirm_password: "",
      subsamount: "",
      contruct_date: '',
      per_amounts: '',
      designation: '',
    },
    // Month Table
    fields: [
      //  'index',
      {
        key: "vendor_id",
        label: "Vendor Id",
        sortable: true,
      }
      ,{
        key: "vendor_name",
        label: "Vendor Name",
        sortable: true,
      },
      {
        key: "contact_no",
        label: "Phone No.",
        sortable: true,
      },
      {
        key: "email",
        label: "Email",
        sortable: true,
      },{
        key: "Created_by",
        label: "Created By",
        sortable: true,
      },
    /*   {
        key: "pincode",
        label: "Pincode",
        sortable: true,
      },
      {
        key: "location",
        label: "Location",
        sortable: true,
      },
      {
        key: "registered_by",
        label: "Registered By",
        sortable: false,
      }, */
      {
        key: "venue",
        label: "Action",
        sortable: false,
      },
      /* {
        key: "view",
        label: "View",
        sortable: false,
      }, */
    ],
    include_trail:false,
    biling_status: '',
    vb_pencentage_list: [],
    vb_range_subs: [],
    item: [],
    value: "",

    options: [10, 20, 30, 50],
    vendors: [],
    pageSize: 10,
    show: false,
    load: true,
    filter: "",
    search: "",
    CurrentPage: 1,
    sortBy: "created_at",

    page: 1,
    count: 0,
    totalData: "",
    totalRows: "",

    PerPage: "50",
    pincode_selected: "",
    vendor_name_selected: "",
    location_selected: "",

    pincode_option: [],
    vendor_name_option: [],
    location_option: [],
  }),
  validations: {
    vendorForm: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      contruct_date: { required },
      state: { required },
      designation: { required },
      contact: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
     // password: { required, minLength: minLength(8), passwordCheck },
     // confirm_password: { required, sameAsPassword: sameAs("password") },
      company_name: { required },
     // pan: { panCardNumberCheck },
     // gst_tin: {gstNumberCheck},
     // pincode: { numeric, minLength: minLength(6), maxLength: maxLength(6) },
    },
  },
  methods: {
    percentage_changes()
    {
this.vendorForm.per_amounts=this.percentages;
    },
    subscription_changes()
    {
this.vendorForm.subsamount=this.subscriptions;
    },
    showDate()
    {
      const dateObj = new Date();
      const currentDate = dateObj.getFullYear()+"-"+dateObj.getMonth()+"-"+dateObj.getDate();
      this.vendorForm.contruct_date = currentDate;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.vendorForm[name];
      return $dirty ? !$error : null;
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$v.vendorForm.$touch();
      if (this.$v.vendorForm.$anyError) {
        return;
      } else {
        this.handleSubmit();
      }
    },

    close() {
      this.$nextTick(() => {
        this.$bvModal.hide("RegisterVendorModal");
        this.vendorForm = {
          first_name: "",
          last_name: "",
          email: "",
          contact: "",
          address: "",
          company_name: "",
          city: "",
          state: "",
          pincode: "",
          gst_tin: "",
          pan: "",
          password: "",
          confirm_password: "",
          designation: "",
        };
        //this.$v.$reset();
      });
    },
    handleSubmit() {
      this.$v.vendorForm.$touch();
      if (!this.$v.vendorForm.$anyError) {
      if(this.biling_status=='')
      {
        this.$root.$refs.app.showToast("danger", 'Select Your plan');
        return true
      }
      else
      {
        if(this.biling_status=='percentage')
        {
          if(this.fixed_percentage==true)
          {
            if(this.vendorForm.per_amounts=='')
            {
              this.$root.$refs.app.showToast("danger", 'Please Enter Percentage ');
              return true
            }
          }
        }
        else  if(this.biling_status=='subs')
        {
          if(this.vendorForm.subsamount=="")
          {
            this.$root.$refs.app.showToast("danger", 'Please Enter Subscription Amount ');
            return true
          }
        }
       
      }
        axios
          .post("/admin/register_vendor", {
            first_name: this.vendorForm.first_name,
            last_name: this.vendorForm.last_name,
            email: this.vendorForm.email,
            phone: this.vendorForm.contact,
            password: this.vendorForm.password,
            password_confirm: this.vendorForm.confirm_password,
            address: this.vendorForm.address,
            gst_no: this.vendorForm.gst_tin,
            pan_no: this.vendorForm.pan,
            company: this.vendorForm.company_name,
            city: this.vendorForm.city,
            state_code: this.vendorForm.state,
            biling_status: this.biling_status,
            subsamount: this.vendorForm.subsamount,
            contruct_date: this.vendorForm.contruct_date,// include_trail  with_trail without_trail per_amounts end_date
            trail_end_date:this.end_date,
            percentage:this.vendorForm.per_amounts,
            designation:this.vendorForm.designation,
            include_trail:this.include_trail,
            with_trail:this.with_trail,
            without_trail:this.without_trail, 
            fixed_percentage:this.fixed_percentage, 
          })
          .then((resp) => {
            if (resp.data.status) {
              this.close();
              //this.vendorListing();
              //Manage_vendors
              this.$swal({
					title: 'New Vendor ',
					text: "New Vendor Created Successfully",
					type: 'Success',
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Close!',
                    icon: "success"
					}).then((result) => {
					if (result.value) {
						result.value
					}
				});
        this.$router.push("/Manage_vendors");
              this.$root.$refs.app.showToast("success", resp.data.message);
            } else {
              this.$root.$refs.app.showToast("danger", resp.data.message);
            }
          });
      }
    },
    getRequestParams(page, pageSize, sortBy = "venue_name") {
      let params = {};
      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      params["sortBy"] = sortBy;
      return params;
    },
    filterListing() {
      axios.get("/admin/vendor_pincode_city_listing").then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.pincode_option = resp.data.vendor_pincodes;
            this.location_option = resp.data.vendor_cities;
            this.vendor_name_option = resp.data.vendor_names;
          }
        }
      });
    }, 
  auto_contract_date() {
      axios.get("/admin/auto_contract_date").then((resp) => {
        
        this.vendorForm.contruct_date = resp.data.cont_date;
            this.start_date = resp.data.bill_date;
            this.end_date = resp.data.end_date;
            this.with_trail = resp.data.with_trail;
            this.without_trail = resp.data.without_trail;
        
       
      });
    },
    resetFilter() {
      (this.pincode_selected = ""),
        (this.vendor_name_selected = ""),
        (this.location_selected = ""),
        this.vendorListing();
    },
    vendorListing() {
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post("/admin/get_vendors", {
          params,
          search_keyword: keyword,
          pincode: this.pincode_selected,
          city: this.location_selected,
          vendor_name: this.vendor_name_selected,
        })
        .then((resp) => {
          if (resp.data.status_code == 200) {
            this.vendors = [];
            if (resp.data.status) {
              var $createdBy = "";
              this.vb_pencentage_list =   resp.data.vb_pencentage_list;
              this.vb_range_subs =   resp.data.vb_range_subs;
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.vendor_details.length;
              for (var i = 0; i < resp.data.vendor_details.length; i++) {
                if (resp.data.vendor_details[i].created_by_roles == null) {
                  $createdBy = "Self";
                } else {
                    if(resp.data.vendor_details[i].created_by_roles.role.role_type === 'admin')
                    {
                       $createdBy = 'Admin';
                    }
                }
                $createdBy = $createdBy.charAt(0).toUpperCase() + $createdBy.slice(1);
                this.vendors.push({
                  id: resp.data.vendor_details[i].user_id,
                  vendor_name:
                    resp.data.vendor_details[i].first_name +
                    " " +
                    resp.data.vendor_details[i].last_name,
                  vendor_id: resp.data.vendor_details[i].vendor_auto_id,
                  contact_no: resp.data.vendor_details[i].phone,
                  email: resp.data.vendor_details[i].email,
                  pincode: resp.data.vendor_details[i].pincode,
                  location: resp.data.vendor_details[i].city,
                  registered_by: $createdBy,
                  Created_by:resp.data.vendor_details[i].createdby,
                });
              }
              this.show = false;
              this.load = false;
            } else {
              this.count = this.totalData = resp.data.total_records;
              this.totalRows = resp.data.vendor_details.length;
              this.show = false;
              this.load = false;
            }
          }
        });
      this.show = false;
      this.load = false;
    },
    handlePageChange(value) {
      this.page = value;
      this.load = true;
      this.vendorListing();
    },
    applyFilter() {
      const value = "1";
      this.handlePageChange(value);
    },
    key_search() {
      const value = "1";
      this.handlePageChange(value);
    },
    ExportVendor() {
      var keyword = this.search;
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.sortBy
      );
      axios
        .post(
          "/api/export_vendor_pdf",
          {
            params,
            search_keyword: keyword,
            pincode: this.pincode_selected,
            city: this.location_selected,
            vendor_name: this.vendor_name_selected,
          },
          {
            responseType: "blob",
          }
        )
        .then((resp) => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "vendor_list.pdf");
          document.body.appendChild(link);
          link.click();
        });
    },
    state_change()
    {

      for(var i= 0;i<this.myJson.length;i++)
      {
        this.state_code.push({
          value: this.myJson[i].state_code, text:  this.myJson[i].state_code+' - '+this.myJson[i].state
        });
      }
     
    },
    self_date_format:function(date)
    {
      var d1 = new Date(date);
      var ms = [ "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December" ];
      return  ms[d1.getMonth()] + '-' + d1.getFullYear();
    },
  },

  mounted() {
    this.show = true;
    this.vendorListing();
    this.filterListing();
    this.auto_contract_date();
    this.showDate();
    this.state_change();
  },
};
</script>
<style scoped>
#button_width1 {
}

#search {
  max-width: 285px;
}

#select_perpage {
  max-width: 25%;
}
label.fw-medium {
    font-size: 13px;
}

.free_peiod
{
  text-align: end;
    margin-right: 69px !important;
    margin-bottom: 6px;
    margin-top: -15px;
}

.form-custom-control
{
  border: 1px solid #e9dfdf;
    padding: 7px;
    border-radius: 7px;
}
</style>